// Константы
const   pcWidth     =   1366,
        mpcWidth    =   1024,
        tbWidth     =   768,
        mbWidth     =   480,
        minWidth    =   320,

        transition  =   200,
        anim        =   300;

// Переменные
let     slowScroll  =   false;

// iMessage
window.messager =   {
    show: (msg, bottom = true, error = null, timeout = 7000) => {
        const   mWaitTime   =   10,
                stepTime    =   100,
                title       =   "Debet",
                logo        =   typeof path === "string" ? path : "assets/imgs/logo.svg";

        let     bodyInner   =   `
                    <img src="${ logo }" class="messager__img" alt="Логотип" />
                    <div class="messager__content">
                        <div class="messager__title">${ title }</div>
                        <div class="messager__description">${ msg }</div>
                    </div>
                `;

        const   body        =   document.createElement("DIV"),
                close       =   document.createElement("SPAN"),
                messager    =   document.createElement("DIV");

        body.classList.add("messager__body");
        body.innerHTML      =   bodyInner;

        close.classList.add("btn__close", "messager__close");
        close.innerHTML     =   "<span></span><span></span>";

        messager.classList.add("messager");
        messager.setAttribute("data-time", "0");
        messager.appendChild(close);
        messager.appendChild(body);

        window.messager.hide();

        if(typeof error === "boolean"){
            if(error){
                messager.classList.add("messager_error");
            }else{
                messager.classList.add("messager_success");
            }
        }

        if(
            typeof bottom === "boolean"
            && !bottom
        ){
            messager.classList.add("messager_top");
        }

        document.getElementsByTagName("body")[0].appendChild(messager);

        messager.addEventListener("mouseover", e => e.target.setAttribute("data-time", "0"));

        setTimeout(() => {
            messager.classList.add("messager_show");

            const interval    =   setInterval(() => {
                const   currentTime =   parseInt(messager.getAttribute("data-time"), 10);

                if(currentTime <= timeout){
                    messager.setAttribute("data-time", currentTime + stepTime);
                }else{
                    clearInterval(interval);
                    window.messager.hide(messager);
                }
            }, stepTime);

            close.addEventListener("click", e => {
                clearInterval(interval);
                window.messager.hide(messager);
            });
        }, mWaitTime);
    },
    hide: (messager = false) => {
        if(messager){
            messager.classList.remove("messager_show");

            setTimeout(() => {
                messager.remove();
            }, anim);
        }else{
            document.querySelectorAll(".messager").forEach(msgr => window.messager.hide(msgr));
        }
    },
    error: (msg, bottom = true) => {
        window.messager.show(msg, bottom, true);
    },
    success: (msg, bottom = true) => {
        window.messager.show(msg, bottom, false);
    }
};

onReady(() => {
    const   isMob           =   isMobile() || window.innerWidth < mpcWidth,
            headerBounds    =   document.querySelector(".header").getBoundingClientRect();



    /// События с интернетом
    // window.addEventListener("online", () => window.location.reload());
    // window.addEventListener("offline", () => window.location.reload());



    //// Если не мобильный
    if(!isMob){
        //// Блок цитаты с кнопкой
        document.querySelectorAll(".quote_link, .toucher__item").forEach(quote => {
            const   btn                 =   quote.querySelector(".quote__btn, .toucher__btn");

            if(!btn) return;

            quote.addEventListener("mousemove", e => {
                const   bounds          =   quote.getBoundingClientRect();

                if(!btn.getAttribute("style")){
                   btn.style.bottom     =   "auto";
                   btn.style.marginLeft =   0;
                }

                btn.style.left          =   `${ e.clientX - bounds.left - (btn.offsetWidth / 2) }px`;
                btn.style.top           =   `${ e.clientY - bounds.top - (btn.offsetHeight / 2) }px`;

                const   btnBounds       =   btn.getBoundingClientRect();

                if(
                    btnBounds.left + (btnBounds.width / 2) < bounds.left
                    ||  btnBounds.left + (btnBounds.width / 2) > bounds.width + bounds.left
                    ||  btnBounds.top + (btnBounds.height / 2) < bounds.top
                    ||  btnBounds.top + (btnBounds.height / 2) > bounds.height + bounds.top
                ){
                    btn.classList.add("out");
                    return;
                }


                btn.classList.remove("out");
            });
        });



        /// Скролл блока "почему мы?"
        document.querySelectorAll(".why_slider").forEach(why => {
            const   body                =   why.querySelector(".why__body"),
                    slider              =   why.querySelector(".why__slider"),
                    slides              =   why.querySelectorAll(".why__slider .why__slide");

            if(
                !body
                || !slider
                || !slides
            ) return;

            // Локальные переменные
            let     slidesWidth         =   0,
                    slideWidth          =   0;

            // Вычисление ширины слайдера
            slides.forEach(slide => {
                const   slideStyle      =   slide.currentStyle || window.getComputedStyle(slide);

                slideWidth              =   parseInt(slideStyle.width) + parseInt(slideStyle.paddingLeft) + parseInt(slideStyle.paddingRight);
                slidesWidth             +=  slideWidth + parseInt(slideStyle.marginLeft) + parseInt(slideStyle.marginRight);
            });

            // Если есть такой же слайдер после
            if(
                why?.nextElementSibling
                && why.nextElementSibling.classList.contains("why_slider")
            ){
                why.nextElementSibling.style.marginTop  =   `-${ why.offsetHeight }px`;

                /*why.querySelectorAll(".why__slider").forEach(slider => {
                    slider.innerHTML    +=  "<li class='why__slide' style='opacity: 0'></li>";
                });*/
            }

            document.addEventListener("scroll", () => {
                const   bounds          =   why.getBoundingClientRect();

                if(!why.classList.contains("why_initialized")){
                    if(inView(why)){
                        const   bodyStyle   =   body.currentStyle || window.getComputedStyle(body);


                        why.style.height    =   `${ why?.nextElementSibling && why.nextElementSibling.classList.contains("why_slider") ? slidesWidth + slideWidth : slidesWidth }px`;
                        body.style.width    =   `${ bounds.width - parseInt(bodyStyle.paddingLeft) - parseInt(bodyStyle.paddingRight) }px`;

                        // Добавляем скролл, чтобы не было прыжка
                        if(
                            bounds.top < 0
                            && bounds.bottom > 0
                        ){
                            document.documentElement.scrollTop  +=  slidesWidth - parseInt(body.offsetHeight);
                            slider.scrollLeft                   =   slidesWidth - parseInt(slider.offsetWidth);
                        }

                        why.classList.add("why_initialized");
                    }

                    return;
                }

                const   bodyBounds      =   body.getBoundingClientRect(),
                        nextWhy         =   why?.nextElementSibling ? why.nextElementSibling.querySelector(".why__body") : null,
                        offsetHeight    =   (document.documentElement.clientHeight - bodyBounds.height - headerBounds.height) / 2;

                // Плавающий блок
                if(offsetHeight + headerBounds.height > bounds.top){
                    body.classList.add("why__body_fixed");
                    body.classList.toggle("why__body_finished", bounds.bottom - document.documentElement.clientHeight + offsetHeight < 0);

                    // Следующий элемент
                    if(
                        bodyBounds?.top
                        && nextWhy
                    ){
                        if(
                            nextWhy.classList.contains("why__body_fixed")
                            || body.classList.contains("why__body_finished")
                        ){
                            body.style.opacity  =   0;
                        }else{
                            body.style.opacity  =   null;
                        }
                    }

                    // Fixed
                    if(body.classList.contains("why__body_finished")){

                    }else{
                        // Скроллинг контента
                        //if(bounds.top < 0){
                        slider.scrollLeft       =   (slidesWidth - slideWidth) * (bounds.top * -1) / ((bounds.height - bodyBounds.height) - (why?.nextElementSibling && why.nextElementSibling.classList.contains("why_slider") ? slideWidth : 0));
                        //}

                        // Выделение активных элементов
                        let scrollLeft          =   slider.scrollLeft;

                        slides.forEach((slide, index) => {
                            if(index > 0){
                                const   slideStyle      =   slide.currentStyle || window.getComputedStyle(slide);

                                scrollLeft              -=  parseInt(slideStyle.width) + parseInt(slideStyle.marginLeft) + parseInt(slideStyle.marginRight);
                            }

                            if(
                                scrollLeft >= 0
                                || index === 0
                            ){
                                slide.classList.add("why__slide_active");
                                return;
                            }

                            slide.classList.remove("why__slide_active")
                        });
                    }

                    if(!body.style.top){
                        body.style.top  =   `${ headerBounds.height + offsetHeight }px`;
                    }
                }else{
                    body.classList.remove("why__body_fixed");
                    body.style.top          =   null;
                    slider.scrollLeft       =   0;
                }
            }, { passive: true });
        });



        /// Скролл блока "шаги"
        document.querySelectorAll(".stages__fix").forEach(stages => {
            const   slider              =   stages.querySelector(".stages__list"),
                    slides              =   stages.querySelectorAll(".stages__list .stages__item");

            if(
                !slider
                || !slides
            ) return;

            // Локальные переменные
            let     slidesWidth         =   0,
                    slideWidth          =   0;

            // Константы
            const   sliderStyle         =   slider.currentStyle || window.getComputedStyle(slider);

            // Вычисление ширины слайдера
            slides.forEach(slide => {
                const   slideStyle      =   slide.currentStyle || window.getComputedStyle(slide);

                slideWidth              =   parseInt(slideStyle.width) + parseInt(slideStyle.paddingLeft) + parseInt(slideStyle.paddingRight);
                slidesWidth             +=  slideWidth + parseInt(slideStyle.marginLeft) + parseInt(slideStyle.marginRight);
            });

            document.addEventListener("scroll", () => {
                const   bounds          =   stages.getBoundingClientRect();

                if(!stages.classList.contains("stages_initialized")){
                    if(inView(stages)){
                        stages.classList.add("stages_initialized");

                        stages.style.height                     =   `${ slidesWidth + parseInt(sliderStyle.paddingLeft) + parseInt(sliderStyle.paddingRight) }px`;

                        slider.style.width                      =   `${ document.documentElement.clientWidth - (parseInt(sliderStyle.paddingLeft) + parseInt(sliderStyle.paddingRight)) }px`;
                        // slider.style.marginLeft              =   `-${ document.documentElement.clientWidth - bounds.width }px`;

                        // Добавляем скролл, чтобы не было прыжка
                        if(
                            bounds.top < 0
                            && bounds.bottom > 0
                        ){
                            document.documentElement.scrollTop  +=  slidesWidth - parseInt(slider.offsetHeight);
                            slider.scrollLeft                   =   slidesWidth - parseInt(slider.offsetWidth);
                        }else{
                            slider.scrollLeft                   =   document.documentElement.clientWidth - bounds.width;
                        }
                    }

                    return;
                }

                const   sliderBounds    =   slider.getBoundingClientRect(),
                        offsetHeight    =   (document.documentElement.clientHeight - sliderBounds.height - headerBounds.height) / 2;

                // Плавающий блок
                if(offsetHeight + headerBounds.height > bounds.top){
                    slider.classList.add("stages__list_fixed");
                    slider.classList.toggle("stages__list_finished", bounds.bottom - document.documentElement.clientHeight + offsetHeight < 0);

                    // Fixed
                    if(slider.classList.contains("stages__list_finished")){

                    }else{
                        // Скроллинг контента
                        if(bounds.top < 0){
                            slider.scrollLeft   =   ((slidesWidth + parseInt(sliderStyle.paddingLeft) + parseInt(sliderStyle.paddingRight)) - document.documentElement.clientWidth) * ((bounds.top * -1) / (bounds.height - sliderBounds.height));
                        }
                    }

                    if(!slider.style.top){
                        slider.style.top    =   `${ headerBounds.height + offsetHeight }px`;
                    }
                }else{
                    slider.classList.remove("stages__list_fixed");
                    slider.style.top        =   null;
                    slider.scrollLeft       =   0;
                }
            }, { passive: true });
        });



        /// Скролл блока "help со списком"
        document.querySelectorAll(".help__fix").forEach(help => {
            const   body                =   help.querySelector(".help_list"),
                    slider              =   help.querySelector(".help__texts, .help__items"),
                    slides              =   help.querySelectorAll(".help__texts .help__text, .help__items .help__item");

            if(
                !body
                || !slider
                || !slides
            ) return;

            // Локальные переменные
            let     slidesWidth         =   0,
                    slideWidth          =   0;

            // Константы
            const   sliderStyle         =   slider.currentStyle || window.getComputedStyle(slider),
                    bodyStyle           =   body.currentStyle || window.getComputedStyle(body),
                    parentStyle         =   slider.parentElement.currentStyle || window.getComputedStyle(slider.parentElement);

            // Вычисление ширины слайдера
            slides.forEach(slide => {
                const   slideStyle      =   slide.currentStyle || window.getComputedStyle(slide);

                slideWidth              =   parseInt(slideStyle.height) + parseInt(slideStyle.paddingTop) + parseInt(slideStyle.paddingBottom);
                slidesWidth             +=  slideWidth + parseInt(slideStyle.marginTop) + parseInt(slideStyle.marginBottom);
            });

            slidesWidth > parseInt(parentStyle.height) && document.addEventListener("scroll", () => {
                const   bounds          =   help.getBoundingClientRect();

                if(!help.classList.contains("help_initialized")){
                    if(inView(help)){
                        help.classList.add("help_initialized");

                        help.style.height                     =   `${ slidesWidth + parseInt(sliderStyle.paddingLeft) + parseInt(sliderStyle.paddingRight) }px`;

                        // Добавляем скролл, чтобы не было прыжка
                        if(
                            bounds.top < 0
                            && bounds.bottom > 0
                        ){
                            document.documentElement.scrollTop  +=  slidesWidth - parseInt(slider.offsetHeight);
                            slider.scrollTop                    =   slidesWidth - parseInt(slider.offsetWidth);
                        }else{
                            slider.scrollTop                    =   document.documentElement.clientWidth - bounds.width;
                        }
                    }

                    return;
                }

                const   bodyBounds      =   body.getBoundingClientRect(),
                        offsetHeight    =   (document.documentElement.clientHeight - bodyBounds.height - headerBounds.height) / 2;

                // Плавающий блок
                if(offsetHeight + headerBounds.height > bounds.top){
                    body.classList.add("help__list_fixed");
                    body.classList.toggle("help__list_finished", bounds.bottom - document.documentElement.clientHeight + offsetHeight < 0);

                    body.style.width    =   `${ bounds.width - parseInt(bodyStyle.paddingLeft) - parseInt(bodyStyle.paddingRight) }px`;
                    body.style.left     =   `${ bounds.left }px`;

                    // Fixed
                    if(body.classList.contains("help__list_finished")){

                    }else{
                        // Скроллинг контента
                        if(bounds.top < 0){
                            if(slider.classList.contains("help__items")){
                                let percentScroll           =   (bounds.top * -1) / (bounds.height - bodyBounds.height);
                                    maxScroll               =   slidesWidth * percentScroll,
                                    summScroll              =   0;

                                slides.forEach((slide, index) => {
                                    const   slideStyle      =   slide.currentStyle || window.getComputedStyle(slide);

                                    slide.style.marginTop   =   null;

                                    if(
                                        summScroll < maxScroll
                                        && index > 0
                                        && percentScroll > 0.05
                                    ){
                                        slide.style.marginTop   =   `-135px`;
                                    }

                                    if(index > 0){
                                        summScroll          +=  parseInt(slideStyle.height) + parseInt(slideStyle.paddingTop) + parseInt(slideStyle.paddingBottom);
                                    }
                                });
                                console.log( slidesWidth, slidesWidth * ((bounds.top * -1) / (bounds.height - bodyBounds.height)));
                            }else{
                                slider.parentElement.scrollTop =   (slidesWidth - parseInt(bodyStyle.paddingLeft) - parseInt(bodyStyle.paddingRight)) * ((bounds.top * -1) / (bounds.height - bodyBounds.height));
                            }
                        }
                    }

                    if(!slider.style.top){
                        body.style.top      =   `${ headerBounds.height + offsetHeight }px`;
                    }
                }else{
                    body.classList.remove("help__list_fixed");
                    body.style.top          =   null;
                    body.style.left         =   null;
                    body.style.width        =   null;
                    slider.parentElement.scrollTop        =   0;
                }
            }, { passive: true });
        });



        /// Фиксируем высоту блока "Help с выпадающим"
        document.querySelectorAll(".help_droptabs").forEach(help => {
            // help.style.height   =   `${ parseInt((help.currentStyle || window.getComputedStyle(help)).height) }px`;
        });
    }else{
        // Если мобильный

        /// Удаление в корзине
        document.querySelectorAll(".basket__item").forEach(item => {
            let lastX   =   0;

            item.addEventListener("touchstart", e => {
                lastX   =   e?.touches[0]?.clientX ?? 0;
            });

            item.addEventListener("touchend", e => {
                item.classList.toggle("basket__item_touch", (e?.changedTouches[0]?.clientX ?? 0) < lastX);
                lastX   =   e?.changedTouches[0]?.clientX ?? 0;
            });
        });



        /// Скролл на мобильном блока "преимуществ (фигуры)"
        document.querySelectorAll(".advantages_figures .advantages__figure").forEach(advantage => {
            const   items   =   advantage.querySelectorAll(".advantages__name, .icon-mini"),
                    bound   =   window.innerHeight / 3;

            items.length > 0 && document.addEventListener("scroll", e => {
                const   bounds  =   advantage.getBoundingClientRect(),
                        top     =   bounds.top - bounds.height / 2;

                let     percent =   top <= bound ? 100 : 0;

                if(top > bound && top < bound * 2){
                    percent     =   (100 - ((top - bound) * 100) / bound);
                }

                items.forEach(item => {
                    item.style.marginTop   =   `${ 25 * (percent / 100) }px`;
                });
            }, { passive: true });
        });



        /// Скролл блока "почему мы?"
        document.querySelectorAll(".why__slider").forEach(why => {
            const   items       =   why.querySelectorAll(".why__slide");

            if(items.length <= 0) return;

            const   setActive   =   () => {
                let scroll      =   why.scrollTop + parseInt(why.offsetHeight),
                    itemsHeight =   0;

                items.forEach(item => {
                    itemsHeight +=  item.offsetHeight;
                    scroll      -=  item.offsetHeight * 0.9;

                    if(scroll >= 0){
                        item.classList.add("why__slide_active");
                        return;
                    }

                    item.classList.remove("why__slide_active")
                });

                if(why.scrollTop + 5 >= why.scrollHeight - why.offsetHeight){
                    why.style.pointerEvents =   "none";

                    setTimeout(() => { why.style.pointerEvents  =   "auto"; }, 1000);
                }
            }

            why.addEventListener("scroll", e => { setActive(); }, { passive: true });
            setActive();
        });



        /// Скролл блока "help со списком"
        document.querySelectorAll(".help_list .help__right").forEach(help => {
            const   items       =   help.querySelectorAll(".help__text");

            if(items.length <= 0) return;

            help.addEventListener("scroll", e => {
                if(help.scrollTop + 5 >= help.scrollHeight - help.offsetHeight){
                    help.style.pointerEvents =   "none";

                    setTimeout(() => { help.style.pointerEvents  =   "auto"; }, 1000);
                }
            }, { passive: true });
        });
    }



    // Модальное окно
    document.querySelectorAll(".btn[data-modal]").forEach(btn => btn.addEventListener("click", e => typeof btn.getAttribute("data-modal") === "string" && document.querySelectorAll(`.modal[data-modal="${ btn.getAttribute("data-modal") }"]`).forEach(modal => modal.classList.toggle("modal_active"))));



    // Эффект по приближению к подвалу сайта или проскроллив первый экран
    document.addEventListener("scroll", () => {
        document.querySelector("main").classList.toggle("active", inView(document.querySelector(".footer")));
        document.querySelector("body").classList.toggle("sscreen", document.documentElement.scrollTop > window.innerHeight);
    }, { passive: true });



    //// Плавный скролл к блоку
    document.querySelectorAll("[href^='#'], [data-src^='#']").forEach(link => {
        const   href        =   link.getAttribute("href") ?? link.getAttribute("data-src"),
                block       =   document.getElementById(typeof href === "string" ? href.slice(1) : null);

        if(!block) return;

        link.addEventListener("click", e => {
            e.preventDefault();

            slowScroll      =   true;

            block.scrollIntoView({
                behavior: "smooth"
            });

            addEventListener("scrollend", e => {
                slowScroll  =   false;
            }, { once: true });
        });
    });



    //// Ползунок
    document.querySelectorAll("input[type='range']").forEach(range => range.addEventListener("input", e => {
        const   parent  =   range.parentElement,
                max     =   range.hasAttribute("max") ? parseInt(range.getAttribute("max")) : 0,
                percent =   max > 0 ? (parseInt(range.value) * 100) / max : 0;

        // Вывод значения
        parent.querySelectorAll("var").forEach(item => {
            item.innerText          =   range.value;
            item.style.marginLeft   =   `calc(${ percent }% - ${ item.getBoundingClientRect().width * (range.value / max) }px)`;
        });

        // Ползунок
        parent.querySelectorAll(".input-range__progress").forEach(item => {
            item.style.width        =   `${ percent }%`;
        });
    }));



    //// Шапка сайта
    document.querySelectorAll(".header__burger").forEach(burger => {
        burger.addEventListener("click", e => {
            e.preventDefault();

            const   header          =   document.querySelector(".header"),
                    headerCenter    =   header.querySelector(".header__center");

            if(!header.classList.contains("header_active")){
                if(burger.getAttribute("data-close")){
                    burger.innerText            =   burger.getAttribute("data-close");
                }

                if(headerCenter){
                    headerCenter.style.height   =   `${ window.innerHeight - 102 }px`;
                }

                header.classList.add("header_active");

                setTimeout(() => {
                    header.classList.add("header_active-2");
                }, transition * 3);
            }else{
                if(burger.getAttribute("data-open")){
                    burger.innerText            =   burger.getAttribute("data-open");
                }

                header.classList.remove("header_active-2");

                setTimeout(() => {
                    if(headerCenter){
                        headerCenter.style.height   =   null;
                    }

                    header.classList.remove("header_active");
                }, transition * 3);
            }
        });
    });



    /// Калькулятор
    document.querySelectorAll(".calculator").forEach(calculator => {
        let summ;

        calculator.querySelectorAll("input[data-price]").forEach((input, index, inputs) => input.addEventListener("change", e => {
            summ    =   0;

            inputs.forEach(input => {
                const   price   =   parseInt(input.getAttribute("data-price"));

                switch(input.getAttribute("type")){
                    case "checkbox":
                        summ    +=  price * (+input.checked);
                        break;
                    case "range":
                        summ    +=  price * parseInt(input.value);
                        break;
                }
            });

            document.querySelectorAll(".quote_calculator .quote__text").forEach(text => {
                // Вывод суммы
                text.querySelectorAll("[data-mask]").forEach(result => {
                    result.innerText    =   result.getAttribute("data-mask").replace(/\$/, summ > 0 ? summ : "-");
                });

                // Подмена текста
                if(
                    typeof text.getAttribute("data-lt") === "string"
                    && typeof text.getAttribute("data-mt") === "string"
                ){
                    text.innerText      =   summ > 0 ? text.getAttribute("data-mt") : text.getAttribute("data-lt");
                }
            });
        }));
    });



    //// Этапы, шаги, широкий блок
    document.documentElement.clientWidth >= mpcWidth && document.querySelectorAll(".stages, .steps, .block__scroll_full").forEach(stages => {
        const   style           =   stages.currentStyle || window.getComputedStyle(stages),
                width           =   parseInt(style.width);

        if(
            isNaN(width)
            || width <= 0
        ) return;

        if(
            stages.classList.contains("stages")
            || stages.classList.contains("block__scroll_full")
        ){
            // Если этапы
            stages.style.width  =   `${ width + ((document.documentElement.clientWidth - width) / 2) }px`;
            return;
        }

        // Если шаги
        let     stepActiveWidth =   null;

        const   steps           =   stages.querySelectorAll(".steps__item"),
                stepActive      =   stages.querySelector(".steps__item_active"),
                stepActiveStyle =   stepActive && (stepActive.currentStyle || window.getComputedStyle(stepActive)),
                stepStyle       =   steps[steps.length - 1].currentStyle || window.getComputedStyle(steps[steps.length - 1]),
                stepSetWidth    =   ()  =>  {
                    steps.forEach(step => {
                        if(!step.classList.contains("steps__item_active")){
                            if(!stepActiveWidth){
                                stepActiveWidth =   parseInt(stepActiveStyle?.width ?? 0) + parseInt(stepActiveStyle?.paddingLeft ?? 0) + parseInt(stepActiveStyle?.paddingRight ?? 0);
                            }

                            step.style.width    =   `${ ((width - stepActiveWidth) / (steps.length - 1)) - parseInt(stepStyle.marginLeft) }px`;
                        }
                    });
                };

        steps.forEach(step => step.addEventListener("mouseover", e => {
            e.preventDefault();

            if(!step.classList.contains("steps__item_active")){
                steps.forEach(item => item.classList.remove("steps__item_active"));
                step.classList.add("steps__item_active");

                stepSetWidth();
            }
        }));

        stepSetWidth();
    });



    //// Слайдер кликер
    document.querySelectorAll(".toucher__item").forEach(toucher => {
        toucher.querySelectorAll(".toucher__btn").forEach(btn => btn.addEventListener("click", e => {
            e.preventDefault();

            let beforeTarget        =   true;

            toucher.parentElement.querySelectorAll(".toucher__item").forEach(item => {
                item.classList.remove("toucher__item_active", "toucher__item_previous");

                if(item == toucher){
                    beforeTarget    =   false;
                }

                if(beforeTarget){
                    toucher.parentElement.appendChild(item);
                }
            });

            toucher.classList.add("toucher__item_previous");

            if(toucher?.nextElementSibling){
                const   newToucher      =   toucher.nextElementSibling;

                newToucher.classList.add("toucher__item_active");

                if(!isMob){
                    const   bounds      =   newToucher.getBoundingClientRect();

                    newToucher.querySelectorAll(".toucher__btn").forEach(btn => {
                        if(!btn.getAttribute("style")){
                            btn.style.bottom     =   "auto";
                            btn.style.marginLeft =   0;
                        }

                        btn.style.left  =   `${ e.clientX - bounds.left - (btn.offsetWidth / 2) }px`;
                        btn.style.top   =   `${ e.clientY - bounds.top - (btn.offsetHeight / 2) }px`;
                    });
                }
            }
        }));
    });



    //// Табы
    document.querySelectorAll(".tabs__item").forEach(tab => {
        tab.addEventListener("click", e => {
            e.preventDefault();

            let tabs    =   tab.parentElement,
                tabType =   tab.getAttribute("data-type") ?? tab.innerText;

            while(tabs && tabs.parentNode){
                if(tabs.classList.contains("tabs")) break;

                tabs   =   tabs.parentNode;
            }

            tabs.querySelectorAll(".tabs__item").forEach(item => item.classList.remove("tabs__item_active"));
            tabs.querySelectorAll(".tabs__content").forEach(item => item.classList.remove("tabs__content_active"));

            tab.classList.add("tabs__item_active");
            tabs.querySelectorAll(`.tabs__content[data-id="${ tab.getAttribute("data-id") }"]`).forEach(item => item.classList.add("tabs__content_active"));

            // Табы-карточки
            document.querySelectorAll(".tabs__card").forEach(card => card.classList.toggle("tabs__card_hide", tabType !== "all" && card.getAttribute("data-tab") !== tabType));
        });
    });



    //// Выпадающие табы, Help (лист)
    document.querySelectorAll(".droptabs:not(.droptabs_horizontal) .droptabs__item, .help_list .help__item").forEach(tab => {
        tab.addEventListener("click", e => {
            e.preventDefault();

            let tabs    =   tab.parentElement,
                clsName =   tab.classList.contains("help__item") ? "help" : "droptabs";

            while(tabs && tabs.parentNode){
                if(tabs.classList.contains(clsName)) break;

                tabs   =   tabs.parentNode;
            }

            // Всегда 1 таб активен
            if(tabs.classList.contains("droptabs_by1")){
                tabs.querySelectorAll(`.${ clsName }__item`).forEach(item => item.classList.remove(`${ clsName }__item_active`));
                tab.classList.add(`${ clsName }__item_active`);

                return;
            }

            tab.classList.toggle(`${ clsName }__item_active`);
        });
    });



    //// Табы сцены
    document.querySelectorAll(".droptabs_horizontal").forEach(droptabs => {
        let     initBounds      =   null;

        const   list            =   droptabs.querySelector(".droptabs__list"),
                items           =   droptabs.querySelectorAll(".droptabs__item"),

                style           =   items[0].currentStyle || window.getComputedStyle(items[0]),
                initWidth       =   parseInt(style.marginRight) * -1,
                initHeight      =   parseInt(droptabs.offsetHeight),
                offsetWidth     =   (document.documentElement.clientWidth - droptabs.offsetWidth) / 2,

                resetItems      =   (index) => {
                    items.forEach((item, i) => {
                        if(
                            index === i
                            || (
                                isMob
                                && index === 0
                            )
                        ) return;


                        if(!isMob){
                            if(index - 1 === i) return;

                            if(item.style.width){
                                item.style.width            =   null;
                            }
                        }else{
                            if(index > i){
                                item.style.top              =   `0%`;
                                //item.style.transform        =   `scale(1)`;
                            }
                        }
                    });
                },
                setWidth        =   (index = 0, cof = 1) => {
                    resetItems(index);

                    if(cof > .98){
                        cof     =   1;
                    }else if(cof < .02){
                        cof     =   0;
                    }

                    if(!isMob){
                        const newWidth                      =   (document.documentElement.clientWidth - offsetWidth - (initWidth * items.length)) * cof;

                        items[index].style.width            =   `${ newWidth }px`;

                        items.forEach(item => item.querySelectorAll(".droptabs__content").forEach(content => {
                            if(!content.style.minWidth){
                                const    style              =   content.currentStyle || window.getComputedStyle(content);

                                content.style.minWidth      =   `${ newWidth - parseInt(style.paddingLeft) - parseInt(style.paddingRight) }px`;
                            }
                        }));

                        if(index - 1 >= 0){
                            items[index - 1].style.width    =   cof === 1 ? null : `${ (document.documentElement.clientWidth - offsetWidth - (initWidth * items.length)) * (1 - cof) }px`;
                        }
                    }else{
                        items[index].style.top              =   `${ 100 * (1 - cof) }%`;
                        //items[index].style.transform        =   `scale(${ 1 - (0.2 * (1 - cof)) })`;
                    }
                },
                onScroll        =   () => {
                    const   bounds                  =   droptabs.getBoundingClientRect();

                    if(!initBounds){
                        initBounds                  =   bounds;
                    }

                    // Инициализация
                    if(!droptabs.classList.contains("droptabs_initialized")){
                        if(inView(droptabs)){
                            droptabs.classList.add("droptabs_initialized");

                            droptabs.style.height       =   `${ initHeight * items.length }px`;

                            items.forEach(item => {
                                item.style.height       =   `${ initHeight }px`;
                            });

                            if(!isMob){
                                droptabs.style.width    =   `${ document.documentElement.clientWidth }px`;
                                droptabs.style.right    =   `${ offsetWidth }px`;
                            }

                            if(initBounds.bottom < 0){
                                setWidth(items.length - 1);
                                window.scrollBy(0, droptabs.getBoundingClientRect().bottom);
                                return;
                            }

                            setWidth(0);
                        }

                        return;
                    }

                    // Если инициализирован
                    const   listBounds      =   list.getBoundingClientRect(),
                            offsetHeight    =   (document.documentElement.clientHeight - listBounds.height - headerBounds.height) / 2,
                            currentSlide    =   parseInt(((bounds.top * -1) + initHeight) / initHeight);

                    // Плавающий блок
                    if(offsetHeight + headerBounds.height > bounds.top){
                        list.classList.add("droptabs_fixed");
                        list.classList.toggle("droptabs_finished", bounds.bottom - document.documentElement.clientHeight + offsetHeight < 0);

                        // Fixed
                        if(list.classList.contains("droptabs_finished")){
                            setWidth(items.length - 1, 1);
                        }

                        if(!list.style.top){
                            list.style.top  =   `${ headerBounds.height + offsetHeight }px`;
                        }
                    }else{
                        list.classList.remove("droptabs_fixed");
                        list.style.top      =   null;
                    }

                    // Показ слайда
                    if(
                        currentSlide >= 0
                        && currentSlide < items.length
                    ){
                        setWidth(currentSlide, currentSlide > 0 ? ((bounds.top * -1) % initHeight) / initHeight : 1);
                    }
                };

            document.addEventListener("scroll", onScroll, { passive: true });
            onScroll();
    });



    //// Инпут число
    document.querySelectorAll(".input-number").forEach(input => {
        const   number  =   input.querySelector("input");

        if(number){
            const   min =   parseInt(number.getAttribute("data-min")) ?? 0,
                    max =   parseInt(number.getAttribute("data-max")) ?? 999;

            // Кнопки
            input.querySelectorAll(".input-number__minus, .input-number__plus").forEach(btn => {
                btn.addEventListener("click", e => {
                    e.preventDefault();

                    const   value       =   parseInt(number.value) ?? 0;

                    if(
                        btn.classList.contains("input-number__minus")
                        && value > min
                    ){
                        number.value    =   value - 1;
                    }else if(
                        btn.classList.contains("input-number__plus")
                        && value < max
                    ){
                        number.value    =   value + 1;
                    }
                });
            });

            // Изменение импута
            number.addEventListener("change", e => {
                const   value           =   parseInt(number.value) ?? 0;

                if(value < min){
                    number.value        =   min;
                }else if(value > max){
                    number.value        =   max;
                }
            });
        }
    });



    //// Корзина
    document.querySelectorAll(".basket__remove").forEach(btn => {
        // Показываем popup по клику
        btn.addEventListener("click", e => {
            e.preventDefault();

            document.querySelectorAll(".basket__popup").forEach(popup => popup.classList.remove("basket__popup_active"));
            btn.parentNode.querySelectorAll(".basket__popup").forEach(popup => popup.classList.add("basket__popup_active"));
        });

        // Закрытие popup
        document.querySelectorAll(".basket__popup").forEach(popup => {
            popup.querySelectorAll(".basket__btns a, .basket__btns span").forEach(btn => {
                btn.addEventListener("click", e => {
                    e.preventDefault();

                    popup.classList.remove("basket__popup_active");

                    // Callback
                    if(!e.target.classList.contains("btn")){
                        let item    =   e.target.parentElement;

                        while(item && item.parentNode){
                            if(item.classList.contains("basket__item")) break;

                            item    =   item.parentNode;
                        }

                        item && item.classList.add("basket__item_removed");

                        // Callback
                        typeof window.onBasketItemRemove === "function" && onBasketItemRemove();
                    }
                });
            });
        });
    });



    //// Dialog
    document.querySelectorAll("dialog[id]").forEach(dialog => {
        const   clear       =   dialog.querySelector(".filter__clear"),
                inputs      =   dialog.querySelectorAll("input"),
                btnFilter   =   document.querySelectorAll(".btn_filter");

        clear.addEventListener("click", e => {
            e.preventDefault();

            inputs.forEach(input => {
                if(input?.type === "checkbox"){
                    input.checked   =   false;
                }else{
                    input.value     =   null;
                }

                input.dispatchEvent(new Event("change"));
            });

            // Все кнопки фильтров на странице
            btnFilter.forEach(btn => btn.classList.remove("btn_filter_filled"));
        });

        if(
            clear
            && inputs.length > 0
        ){
            inputs.forEach(input => input.addEventListener("change", e => {
                let notFilled       =   true;

                inputs.forEach(item => {
                    if(
                        (
                            input?.type === "checkbox"
                            && input.checked
                        ) || (
                            input?.type !== "checkbox"
                            && item.value.length > 0
                        )
                    ){
                        notFilled   =   false;
                    }
                });

                if(notFilled){
                    dialog.classList.remove("filter_filled");

                    // Все кнопки фильтров на странице
                    btnFilter.forEach(btn => btn.classList.remove("btn_filter_filled"));

                    return;
                }

                dialog.classList.add("filter_filled");

                // Все кнопки фильтров на странице
                btnFilter.forEach(btn => btn.classList.add("btn_filter_filled"));
            }));
        }
    });

    document.querySelectorAll("[data-dialog]").forEach(btn => btn.addEventListener("click", e => {
        e.preventDefault();

        document.querySelectorAll(`dialog#${ btn.getAttribute("data-dialog") }`).forEach(dialog => {
            dialog.addEventListener("click", e => e.target.tagName === "DIALOG" && dialog.close());

            if(!dialog.hasAttribute("open")){
                dialog.showModal();
                return;
            }

            dialog.close();
        });
    }));



    //// Select
    document.querySelectorAll(".select").forEach(select => {
        const   selectTitle     =   select.querySelector(".select__title"),
                selectValues    =   select.querySelector(".select__values"),
                selectInput     =   select.querySelector(".select__input"),
                selectCallback  =   select.getAttribute("data-callback");

        if(
            !selectTitle
            || !selectValues
        ) return;

        const   setDefault                  =   () => {
            let defaultValue                =   "";

            if(typeof selectTitle.getAttribute("data-default") === "string"){
                defaultValue                =   selectTitle.getAttribute("data-default");
            }else{
                defaultValue                =   selectValues.querySelector("li:first-child").innerText;
            }

            if(
                selectTitle.innerText.length <= 0
                || (
                    selectInput
                    && !selectInput.value
                )
            ){
                selectTitle.innerText       =   defaultValue;
            }
        };

        select.addEventListener("click", e => {
            select.classList.toggle("select_active");

            if(e.target.tagName === "LI"){
                selectValues.querySelectorAll("li").forEach(li => li.classList.remove("select__item_active"));
                e.target.classList.add("select__item_active");

                selectTitle.innerText   =   e.target.innerText;

                if(selectInput){
                    selectInput.value   =   e.target.innerText;
                    selectInput.dispatchEvent(new Event("change"));
                }

                if(
                    selectCallback
                    && typeof window[selectCallback] === "function"
                ){
                    window[selectCallback](e.target.innerText);
                }
            }
        });

        setDefault();

        if(selectInput){
            selectInput.addEventListener("change", setDefault);
        }
    });



    //// Слайдер дней (days)
    document.querySelectorAll(".days").forEach(days => {
        const   daysInput       =   days.querySelector(".days__input"),
                daysCallback    =   days.getAttribute("data-callback");

        days.addEventListener("click", e => {
            const element       =   e.target.classList.contains("days__item") ? e.target : (e.target.parentElement.classList.contains("days__item") ? e.target.parentElement : null);

            if(element){
                const   value   =   element.querySelector("span:last-child")?.innerText;

                days.querySelectorAll(".days__item").forEach(item => item.classList.remove("days__item_active"));
                element.classList.add("days__item_active");

                if(daysInput){
                    daysInput.value =   value;
                }

                if(
                    daysCallback
                    && typeof window[daysCallback] === "function"
                ){
                    window[daysCallback](value);
                }
            }
        });
    });



    //// Автоскроллинг
    document.querySelectorAll(".autoscroll").forEach(autoscroll => {
        let direction   =   1,
            lastLeft    =   0;

        setInterval(() => {
            if(
                !autoscroll.matches(":active")
                && !slowScroll
            ){
                autoscroll.scrollLeft   +=  direction;

                if(autoscroll.scrollLeft == lastLeft){
                    direction           *=  -1;
                }

                lastLeft                =   autoscroll.scrollLeft;
            }
        }, isMob ? 60 : 30);

        // Скролл по клику и перетаскиванию
        autoscroll.addEventListener("pointerdown", e => autoscroll.setPointerCapture(e.pointerId));
        autoscroll.addEventListener("pointerup", e => autoscroll.releasePointerCapture(e.pointerId));
        autoscroll.addEventListener("pointermove", e => autoscroll.hasPointerCapture(e.pointerId) && (autoscroll.scrollLeft -= e.movementX));
    });



    //// Оформление
    document.querySelectorAll(".order__radio input").forEach((input, index) => {
        input.addEventListener("change", e => document.querySelectorAll(".order__form").forEach((form, fIndex) => form.classList.toggle("order__form_active", index === fIndex)));
    });



    //// Видео
    document.querySelectorAll(".video[data-iframe]").forEach(video => {
        video.addEventListener("click", e => {
            e.preventDefault();

            const   iframe  =   document.createElement("IFRAME");

            iframe.setAttribute("src", video.getAttribute("data-iframe"));
            iframe.setAttribute("allowfullscreen", "allowfullscreen");
            iframe.setAttribute("allow", "autoplay");

            video.parentNode.insertBefore(iframe, video);
            video.remove();
        });
    });



    //// Анимированное число
    document.querySelectorAll("[data-anim-counter]").forEach(tag => {
        const tagCounter  =   () => {
                // Удаляем событие
                document.removeEventListener("scroll", tagScroll);

                // Анимация
                const animate           =   () => {
                    const   delimeter   =   tag.getAttribute("data-anim-counter-delimeter") ?? " ",
                            content     =   tag.innerText.length > 0 ? tag.innerText.replace(new RegExp(`(${ delimeter }|\s)+`, "g"), '') : 0,
                            counter     =   parseInt(tag.getAttribute("data-anim-counter") ?? parseFloat(content) ?? 0),
                            time        =   parseInt(tag.getAttribute("data-anim-counter-time") ?? 100),
                            speed       =   counter / time,
                            mask        =   tag.getAttribute("data-anim-counter-mask") ?? "$";

                    let     current     =   parseInt(content);

                    if(isNaN(current)){
                        current         =   0;
                    }

                    if(current < counter){
                        tag.innerText   =   mask.replace("$", numberFormat(Math.ceil(current + speed), 0, ".", delimeter));
                        setTimeout(animate, 1);
                        return;
                    }

                    tag.innerText       =   mask.replace("$", numberFormat(counter, 0, ".", delimeter));

                };

                animate();
            },
            tagScroll   =   () => inView(tag) && tagCounter();

        document.addEventListener("scroll", tagScroll, { passive: true });
        tagScroll();
    });



    //// Слайдеры
    document.querySelectorAll(".slider").forEach(slider => {
        const   sliderMob   =   slider.classList.contains("slider_mob"),
                sliderPC    =   slider.classList.contains("slider_pc");
        if(
            (
                isMob
                && sliderPC
                && !sliderMob
            ) || (
                !isMob
                && sliderMob
                && !sliderPC
            )
        ) return;

        const   sliderLoader    =   () => {
                // Удаляем событие
                document.removeEventListener("scroll", sliderScroll);

                // Загрузка скрипта и инициализация
                scriptLoader({
                    src: "https://code.jquery.com/jquery-3.7.1.slim.min.js",
                    check: "jQuery",
                    callback: () => {
                        if(typeof jQuery !== "function") return;

                        scriptLoader({
                            src: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js",
                            check: "slick",
                            callback: () => {
                                if(typeof $()?.slick !== "function") return;

                                window.slick =   true;

                                slider.querySelectorAll(".slider__list").forEach(list => {
                                    const   prevBtn             =   slider.querySelector(".slider__controls [rel='prev']") ?? null,
                                            nextBtn             =   slider.querySelector(".slider__controls [rel='next']") ?? null,
                                            elementWidth        =   typeof $(list).data("slider-width") === "string" ? $($(list).data("slider-width")).eq(0) : null,
                                            params              =   {
                                                dots: false,
                                                speed: 500,
                                                slidesToShow: 2,
                                                prevArrow: prevBtn,
                                                nextArrow: nextBtn,
                                                autoplay: slider.hasAttribute("data-slider-autoplay"),
                                                responsive: [
                                                    {
                                                        breakpoint: mpcWidth,
                                                        settings: {
                                                            slidesToShow: 1
                                                        }
                                                    }
                                                ]
                                            };

                                    // Индивидуальная ширина
                                    if(
                                        elementWidth
                                        && elementWidth.length > 0
                                    ){
                                        $(list).width(elementWidth.width());
                                        params.variableWidth    =   true;
                                    }

                                    // Кнопки
                                    [prevBtn, nextBtn].forEach(btn => btn && btn.addEventListener("click", e => e.preventDefault()));

                                    $(list).slick(params);
                                });
                            }
                        });
                    }
                });
            },
            sliderScroll        =   () => inView(slider) && sliderLoader();

        document.addEventListener("scroll", sliderScroll, { passive: true });
        sliderScroll();
    });



    //// Падающие кнопки
    document.querySelectorAll(".falls__btns").forEach(falls => {
        const   fallsLoader    =   () => {
                // Удаляем событие
                document.removeEventListener("scroll", fallsScroll);

                // Загрузка скрипта и инициализация
                scriptLoader({
                    src: "https://cdnjs.cloudflare.com/ajax/libs/matter-js/0.19.0/matter.min.js",
                    check: "Matter",
                    callback: () => {
                        if(typeof Matter !== "object") return;

                        const   Engine  =   Matter.Engine,
                                Render  =   Matter.Render,
                                World   =   Matter.World,
                                Bodies  =   Matter.Bodies,
                                Runner  =   Matter.Runner,
                                params  =   {
                                    isStatic: true,
                                    render: {
                                        fillStyle: "transparent"
                                    }
                                },
                                canvasSize = {
                                    width: falls.offsetWidth,
                                    height: falls.offsetHeight
                                },
                                engine  =   Engine.create({
                                    timing: {
                                        timeScale: 0.4
                                    }
                                }),
                                render  =   Render.create({
                                    element: falls.parentElement,
                                    engine: engine,
                                    options: {
                                        ...canvasSize,
                                        background: "transparent",
                                        wireframes: false
                                    }
                                }),
                                floor   =   Bodies.rectangle(
                                    canvasSize.width / 2,
                                    canvasSize.height,
                                    canvasSize.width,
                                    50,
                                    params
                                ),
                                wall1   =   Bodies.rectangle(
                                    0,
                                    canvasSize.height / 2,
                                    50,
                                    canvasSize.height,
                                    params
                                ),
                                wall2   =   Bodies.rectangle(
                                    canvasSize.width,
                                    canvasSize.height / 2,
                                    50,
                                    canvasSize.height,
                                    params
                                ),
                                wordElements    =   falls.querySelectorAll(".falls__btn"),
                                wordBodies      =   [...wordElements].map(elemRef => {
                                    const   width   =   elemRef.offsetWidth,
                                        height  =   elemRef.offsetHeight,
                                        part    =   Math.floor(Math.random() * wordElements.length);

                                    return {
                                        body: Matter.Bodies.rectangle(canvasSize.width / (part > 2 ? part : 2), 0, width, height, {
                                            render: {
                                                fillStyle: "transparent"
                                            }
                                        }),
                                        elem: elemRef,
                                        render() {
                                            const { x, y } = this.body.position;
                                            this.elem.style.top = `${y - 10}px`;
                                            this.elem.style.left = `${x - width / 2}px`;
                                            this.elem.style.transform = `rotate(${ this.body.angle }rad)`;
                                        }
                                    };
                                }),
                                mouse           =   Matter.Mouse.create(falls.parentElement),
                                mouseConstraint =   Matter.MouseConstraint.create(engine, {
                                    mouse,
                                    constraint: {
                                        stiffness: 0.2,
                                        render: {
                                            visible: false
                                        }
                                    }
                                });

                            mouse.element.removeEventListener("mousewheel", mouse.mousewheel);
                            mouse.element.removeEventListener("DOMMouseScroll", mouse.mousewheel);

                            World.add(engine.world, [
                                floor,
                                ...wordBodies.map((box) => box.body),
                                wall1,
                                wall2,
                                mouseConstraint
                            ]);

                            render.mouse            =   mouse;

                            Runner.run(engine);
                            Render.run(render);

                            (function rerender() {
                                wordBodies.forEach((element) => {
                                    element.render();
                                });

                                Matter.Engine.update(engine);

                                requestAnimationFrame(rerender);
                            })();
                    }
                });
            },
            fallsScroll        =   () => inView(falls.parentElement) && fallsLoader();

        document.addEventListener("scroll", fallsScroll, { passive: true });
        fallsScroll();
    });



    //// Карты
    typeof mapAPI === "string" && document.querySelectorAll(".map[data-coord-x][data-coord-y]").forEach(map => {
        const   mapLoader   =   () => {
                // Удаляем событие
                document.removeEventListener("scroll", mapScroll);

                // Загрузка скрипта и инициализация
                scriptLoader({
                    src: `https://api-maps.yandex.ru/2.1/?apikey=${ mapAPI }&lang=ru_RU`,
                    check: "ymaps",
                    callback: () => {
                        if(typeof ymaps !== "object") return;

                        ymaps.ready(() => {
                            map.innerHTML       =   null;

                            const   coordX      =   map.getAttribute("data-coord-x"),
                                coordY      =   map.getAttribute("data-coord-y"),

                                myMap       =   new ymaps.Map(map, {
                                    center: [coordX, coordY],
                                    zoom: map.getAttribute("data-zoom") ?? 10,
                                    controls: ["routeButtonControl"]
                                });

                            myMap.geoObjects
                                .add(new ymaps.Placemark([coordX, coordY], {}, {
                                    preset: "islands#blueDotIcon"
                                }));

                            myMap.behaviors.disable("scrollZoom");
                        });
                    }
                });
            },
            mapScroll   =   () => inView(map) && mapLoader();

        document.addEventListener("scroll", mapScroll, { passive: true });
        mapScroll();
    });



    //// Анимация печати
    document.querySelectorAll(".typed[data-variants]").forEach(tag => {
        const   typedVariants   =   JSON.parse(tag.getAttribute("data-variants")),
            typedLoader     =   () => {
                // Удаляем событие
                document.removeEventListener("scroll", typedScroll);

                // Загрузка скрипта и инициализация
                scriptLoader({
                    src: "https://cdnjs.cloudflare.com/ajax/libs/typed.js/2.0.10/typed.min.js",
                    check: "Typed",
                    callback: () => {
                        if(typeof Typed !== "function") return;

                        new Typed(tag, {
                            strings: typedVariants,
                            typeSpeed: 120,
                            startDelay: 200,
                            backSpeed: 30,
                            backDelay: 5000,
                            showCursor: true,
                            cursorChar: "&nbsp;",
                            loop: true
                        });
                    }
                });
            },
            typedScroll   =   () => inView(tag) && typedLoader();

        document.addEventListener("scroll", typedScroll, { passive: true });
        typedScroll();
    });



    //// Fancybox
    document.querySelectorAll("[data-fslightbox]").forEach(link => {
        const   fancyLoader =   () => {
                // Удаляем событие
                document.removeEventListener("scroll", fancyScroll);

                // Загрузка скрипта и инициализация
                scriptLoader({
                    src: "https://cdnjs.cloudflare.com/ajax/libs/fslightbox/3.0.9/index.js",
                    check: "fsLightbox"
                });
                /*scriptLoader({
                    src: "https://code.jquery.com/jquery-3.7.1.slim.min.js",
                    check: "jQuery",
                    callback: () => {
                        if(typeof jQuery !== "function") return;

                        scriptLoader({
                            src: "https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.js",
                            check: "fancybox",
                            callback: () => {
                                if(typeof $?.fancybox !== "object") return;

                                window.fancybox =   true;

                                styleLoader({
                                    src: "https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.css",
                                    callback: () => {
                                        $.fancybox.defaults.i18n.ru = {
                                            CLOSE: "Закрыть",
                                            NEXT: "Следующий",
                                            PREV: "Предыдущая",
                                            MODAL: "Вы можете закрыть окно нажатием ESC",
                                            ERROR: "Что-то пошло не так, попробуйте позже",
                                            IMAGE_ERROR: "Image Not Found",
                                            ELEMENT_NOT_FOUND: "HTML-элемент не найден",
                                            AJAX_NOT_FOUND: "Ошибка Ajax: Не найдено",
                                            AJAX_FORBIDDEN: "Ошибка Ajax: Запрещено",
                                            IFRAME_ERROR: "Ошибка загрузки страницы",
                                        };

                                        $.fancybox.defaults.lang = "ru";
                                    }
                                });
                            }
                        });
                    }
                });*/
            },
            fancyScroll   =   () => inView(link) && fancyLoader();

        document.addEventListener("scroll", fancyScroll, { passive: true });
        fancyScroll();
    });



    //// Маски для инпутов
    document.querySelectorAll("input[data-type]").forEach(input => {
        const   inputLoader =   () => {
                // Удаляем событие
                document.removeEventListener("scroll", inputScroll);

                // Загрузка скрипта и инициализация
                scriptLoader({
                    src: "https://cdnjs.cloudflare.com/ajax/libs/jquery.inputmask/5.0.8/inputmask.min.js",
                    check: "Inputmask",
                    callback: () => {
                        if(typeof Inputmask !== "function") return;

                        const   pasteCallback       =   (input, msg = null) => {
                                const   err         =   input.target.parentNode.querySelector(".input__msg");

                                if(input.target.value.length <= 0){
                                    msg             =   null;
                                }

                                if(err){
                                    err.classList.remove("input__msg_error");
                                    err.innerText   =   msg;

                                    if(msg){
                                        err.classList.add("input__msg_error");
                                    }
                                }
                            };

                        switch(input.getAttribute("data-type")){
                            case "phone":
                                Inputmask({
                                    mask: "+375 (99) 999-99-99",
                                    // clearIncomplete: true,
                                    onincomplete: input => pasteCallback(input, `Введите корректный номер`),
                                    oncomplete: input => pasteCallback(input),
                                }).mask(input);

                                break;
                            case "mail":
                                Inputmask({
                                    mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
                                    greedy: false,
                                    clearIncomplete: true,
                                    onBeforePaste: (value, opts) => {
                                        return value.toLowerCase().replace("mailto:", "");
                                    },
                                    onincomplete: input => pasteCallback(input, `Введите корректный Email`),
                                    oncomplete: input => pasteCallback(input),
                                    definitions: {
                                        '*': {
                                            validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
                                            casing: "lower"
                                        }
                                    }
                                }).mask(input);
                        }
                    }
                });
            },
            inputScroll =   () => inView(input) && inputLoader();

        document.addEventListener("scroll", inputScroll, { passive: true });
        inputScroll();
    });
});


/// Функции
// Мобильный
function isMobile(){
    return /mobile|iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()) && window?.innerWidth < tbWidth;
}

// Элемент в экране
function inView(item){
    const   bounds      =   item.getBoundingClientRect();

    if(
        bounds?.top - document.documentElement.clientHeight < 0
        && bounds?.bottom > 0
    ) return true;

    return false;
}

// Форматирование числа
function numberFormat(number, decimals = 0, dec_point = ".", thousands_sep = " "){
    let sign        =   number < 0 ? "-" : "",
        s_number    =   Math.abs(parseInt(number = (+number || 0).toFixed(decimals))) + "",
        len         =   s_number.length,
        tchunk      =   len > 3 ? len % 3 : 0,
        ch_first    =   (tchunk ? s_number.substr(0, tchunk) + thousands_sep : ""),
        ch_rest     =   s_number.substr(tchunk)
            .replace(/(\d\d\d)(?=\d)/g, "$1" + thousands_sep),
        ch_last     =   decimals ?
            dec_point + (Math.abs(number) - s_number)
                .toFixed(decimals)
                .slice(2) :
            "";

    return sign + ch_first + ch_rest + ch_last;
}

// Загрузчик скрипта
function scriptLoader({ src, check = null, callback = null }) {
    if(!src) return;

    if(
        typeof window[check] === "undefined"
        && !document.querySelector(`script[src="${ src }"]`)
    ){
        const   script      =   document.createElement("SCRIPT");

        script.setAttribute("src", src);

        if(typeof callback === "function"){
            script.onload   =   callback;
        }

        return document.querySelector("body").appendChild(script);
    }

    if(typeof callback === "function"){
        callback();
    }
}

// Загрузчик стилей
function styleLoader({ src, callback = null }) {
    if(!src) return;

    if(!document.querySelector(`link[href="${ src }"]`)){
        const   style       =   document.createElement("LINK");

        style.setAttribute("href", src);
        style.setAttribute("rel", "stylesheet");
        style.setAttribute("type", "text/css");

        if(typeof callback === "function"){
            callback();
        }

        return document.querySelector("body").appendChild(style);
    }

    if(typeof callback === "function"){
        callback();
    }
}

// Запуск, когда загружена страница
function onReady(callback = null) {
    const   interval    =   setInterval(() => {
                if(document?.readyState === "complete"){
                    clearInterval(interval);

                    if(typeof callback === "function"){
                        callback();
                    }
                }
            }, 100);
}